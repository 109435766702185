import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import Table from "../../../components/table";
import Layout from "../../../components/layout";

function App({ location }) {

  const columns = useMemo(
    () => [
      {
        Header: "Navn",
        accessor: "name",
      },
      {
        Header: "Runde",
        accessor: "round",
      },
      {
        Header: "Hjemme",
        accessor: "homeTeam.name"
      },
      {
        Header: "",
        accessor: "result.awayScore45",
        disableSortBy: true
      },
      {
        Header: "",
        accessor: "result.awayScore90",
        disableSortBy: true
      },
      {
        Header: "Borte",
        accessor: "awayTeam.name"
      },
      {
        Header: "Tidspunkt",
        accessor: "timestamp",
        Cell: (date) => (
          <div>
            {new Date(date.cell.value).toLocaleDateString("no-NO")}
          </div>
        ),
      },

    ],
    []
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios("https://api.nifs.no/tournaments/7/stages/681726/matches/");
      setData(result.data);
    })();
  }, []);

  return (
    <Layout location={location} className="App">
      <Table type="scores" hidden="name" title="Terminliste" columns={columns} data={data} />
    </Layout>
  );
}

export default App;
